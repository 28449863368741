// Import the functions you need from the SDKs you need
import { initializeApp , getApps, getApp  } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDyMMewSdB-Cesd7qabVblLPlztfxMxRqE",
  authDomain: "liga-premiere.firebaseapp.com",
  projectId: "liga-premiere",
  storageBucket: "liga-premiere.appspot.com",
  messagingSenderId: "906217120993",
  appId: "1:906217120993:web:86e5d0cad95423108b43a0"
};

// Initialize Firebase


export function firebaseInit() {

    if (!getApps().length) {
             initializeApp(firebaseConfig);
      }else {
        getApp() // if already initialized, use that one
      }            
}
   


export const firebaseStart = () =>  initializeApp(firebaseConfig);