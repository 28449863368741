import * as React from "react"
import {Space, Row, Input , Button  , Col } from "antd"
import {Link} from "gatsby"
import {getAuth, signInWithEmailAndPassword} from "firebase/auth"
import HeroContainer from "../../components/HeroContainer"
import styled from 'styled-components';
import { firebaseInit } from "../../firebaseInit"

import { Typography } from 'antd';



import "../../styles/site.css"
import "antd/dist/antd.css"


const { Text } = Typography;

const Label = styled.p`
  color: white;  
  font-size: 16px;
  margin-bottom: 12px;  
`;

const LoginPage = () => {
  
    const [email, setEmail] = React.useState('')
    const [password, setPassword] = React.useState('')
    const [error,setError] = React.useState('')
    
    React.useEffect(() => {        
        firebaseInit()
              
     /* firebaseInit(firebase)
      setDb(firebase.firestore());
        firebase.auth().onAuthStateChanged(function(user) {
          if (user) {
             // navigate('/');
          } else {
              
          }
         });*/        
      }, []);        
    
    const signIn = () =>{                
        signInWithEmailAndPassword(getAuth(), email, password)
          .then((userCredential) => {
                //navigate('/');    
                console.log("success signin ")
               // console.log(user)
        
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        if(error.code === "auth/invalid-email")
        {
            setError("Correo electrónico inválido")
        }
        if(error.code=== "auth/wrong-password")
        {
           setError("Información incorrecta")
        }

        console.log("error in sign in ")
        console.log(error)
      });
    }
    
    const onEmailChange = (e) =>{
    
        setEmail(e.target.value)
    }
    const onPasswordChange = (e) =>{
    
        setPassword(e.target.value)
    }
    
    
    return (<>
          <div>
          <HeroContainer>
      <div>      
          <Space direction="vertical">
            <Row justify="center">
              <Col>
                <div className="logo" className="w-64 px-5 py-2 mb-16">
                  
                </div>          
              </Col>          
            </Row>
            <Row>                      
                    <Label>Correo electrónico</Label>
                    <Input placeholder="usuario@mail.com" value={email} onChange={onEmailChange} size="large" className="input-dark" ></Input>            
            </Row>
            <Row>
                <Label>Contraseña</Label>
                <Input.Password value={password} onChange={onPasswordChange} placeholder="Contraseña" size="large" className="input-dark"></Input.Password>
            </Row>
    
            {error? <Text type="danger">{error}</Text> : null }   
            <Button onClick={signIn}  type="primary" className="mt-4" block size={'large'}>INICIAR SESIÓN</Button>
    
            </Space>
        
    
        </div>
        </HeroContainer>
    
        
      </div>
    
        </>)
    }
    

    export default  LoginPage